import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

function ContactUs() {
  const { heroImage } = useStaticQuery(query);
  const bgImage = convertToBgImage(getImage(heroImage));

  return (
    <Layout>
      <SEO title="Contact Us" />

      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
        className="-mt-32 h-screen object-cover"
      />

    <section className="py-5 px-4">
      <div className="container max-w-5xl -mt-48 lg:-mt-80 mx-auto">
        <div className="p-2 bg-gray-500 opacity-90">
          <h1 className="font-extrabold text-4xl text-center text-white">Contact Us</h1>
        </div>
        <div className="mt-20 bg-white shadow-lg rounded overflow-hidden text-gray-800">
          <StaticImage
            className="object-cover w-full"
            src="../images/contact-map.png"
            alt="Contact us map image"
            quality={95}                    
          />
        </div>
      </div>
    </section>

    <section className="py-10 px-4">
        <div className="container max-w-5xl mx-auto text-gray-800">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <form className="p-6 shadow rounded bg-white space-y-5">
              <label className="block">
                <input type="text" name="fullName" placeholder="Name" className="w-full form-input rounded-full" />
              </label>
              <label className="block">
                <input type="text" name="emailAddress" placeholder="Email" className="w-full form-input rounded-full" />
              </label>
              <label className="block">
                <textarea name="message" rows={5} placeholder="Type something..." className="w-full form-textarea rounded" />
              </label>
              <button className="py-2 px-4 shadow rounded-full text-white bg-teal-500 hover:bg-teal-700">
                Send Message
              </button>
            </form>
            <div className="space-y-5">
              <p className="font-bold">Subscribe to Hayaan newsletter via Email</p>
              <p className="text-[#68A7CC]">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
              </p>
              <form className="mt-5 flex space-x-5">
                <label className="block">
                  <input type="text" name="email" placeholder="Email Address" className="w-full form-input rounded" />
                </label>
                <button className="py-2 px-4 shadow rounded border-2 border-teal-500 hover:font-bold">
                  SUBSCRIBE
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "contact-hero-bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    avatar: file(relativePath: { eq: "avatar.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default ContactUs
